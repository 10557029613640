

export function MintableEPC({epcCollObj, isSelected, onClick}) {

  return (

    <div id={epcCollObj.type}
              className={"justify-center p-4 "
                // + "type-of-nft-" + epcCollObj.mintDisplayOrder + " "
                + (isSelected && "selected") }
              onClick={onClick}>
      <img className="mx-auto mt-12 "
                src={"/assets/images/epc/" + epcCollObj.titleImageFilename }
                style={{ width: 100 }} />
      <img className="mx-auto mt-2 sm:w-7/12"
                src={"/assets/images/epc/" + epcCollObj.cardImageFilename } />
    </div>

  )

}