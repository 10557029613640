import { useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import {
  toast,
  ToastContainer
} from "../node_modules/react-toastify/dist/react-toastify";
import $ from "jquery";

// ADDED JA
import { storeGetByID } from '../components/FirestoreData';
import { newEPCMint, prepareSend, mintDisplayColls, selectedDefaultEPCColl, collectionEPCsLeftGet } from '../lib/multiversx';
import { MintableEPC } from './MintableEPC';
import { tokenDetails } from '../lib/tokenomics';
import { Token } from "../components/Token";
// END ADDED JA

const numFormat = new Intl.NumberFormat();

export default function EPCs({ firebaseUser, userObj }) {

  // Initialise userObj by getting it from firestore (the storeGetByID call).
  // Do this on each page you need userObj
  useEffect(() => {
    if (!firebaseUser) return;
    storeGetByID('user', firebaseUser.uid); // this will do a setUserObj, updating userObj
  }, [firebaseUser]);


  useEffect(() => {
    if (userObj) accountChooseForPurchase(); // sets purchasing wallet
    console.log('ECPs userObj: ', userObj)
  }, [userObj]);


  const [purchasingWallet, setPurchasingWallet] = useState(null);
  const [selectedEPCCollObj, setSelectedEPCCollObj] = useState(selectedDefaultEPCColl);
  const [pending, setPending] = useState(false);
  const [currentlyAvailable, setCurrentlyAvailable] = useState('');


  useEffect(() => {
    console.log('ECPs selectedEPCCollObj: ', selectedEPCCollObj)
    collDataRefresh();
  }, [selectedEPCCollObj]);


  async function collDataRefresh() {
    setCurrentlyAvailable('...');
    const collEPCsLeft = await collectionEPCsLeftGet(selectedEPCCollObj.creator);
    const formattedStr = isFinite(collEPCsLeft) 
                              ? numFormat.format(collEPCsLeft)
                              : collEPCsLeft
    setCurrentlyAvailable(collEPCsLeft);
  }


  const openAuthModal = () => {
    // close all modal
    $(".modal").hide();
    // open modal now
    $(".auth-modal").fadeIn();
  };


  const handleMint = async (receiver) => {
    if (!firebaseUser) {
      openAuthModal();
      return;
    }
    await newEPCMint(receiver, purchasingWallet);
  };


  const showToast = (type, message) => {
    toast[type](message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };


  function toggleText2() {
    var dots2 = document.getElementById("dots2");
    var moreText2 = document.getElementById("more2");
    var button2 = document.getElementById("morebutton2");

    if (dots2.classList.contains("hidden")) {
      dots2.classList.remove("hidden"); // Show the dots
      moreText2.classList.add("hidden"); // Hide the more text
      button2.innerHTML = "Read More"; // change text of the button
    } else {
      dots2.classList.add("hidden"); // Hide the dots
      moreText2.classList.remove("hidden"); // hide the more text
      button2.innerHTML = "Read Less"; // change text of the button
    }
  }


  // TEMP to choose an account to buy from:
  function accountChooseForPurchase() {
    const wallets = userObj && userObj.wallets;
    if (!wallets) return null;

    // merge the local show/hide switch settings with the firestore wallet list
    // by removing the "hide": true ones from wallets
    const addresses = Object.keys(wallets);
    let address;
    console.log('EPCs addresses: ', addresses);
    for (address of addresses) {
      if (wallets[address].verifiedOwner) break; // DOES NOT MEAN YOU ARE CURRENTLY CONNECTED TODO
    }
    setPurchasingWallet(address); // default take the last one
  }


  useEffect(() => {

    prepareSend(setPending, showToast);

    // JN COMMENTED: TODO GET THE MOVING ANIMATION WORKING AGAIN BEFORE DELETE THIS CODE
    // $(document).ready(function () {

      // // animate selected EPC on-click
      // let divs = $("#types-of-nft div");
      // divs.each(function () {
      //   $(this).click(function () {
      //     // JN ADDED: bring selected EPC to the fore (push others back)
      //     divs.removeClass("selected");
      //     $(this).addClass('selected');
      //     // END JN ADDED: bring selected EPC to the fore (push others back)

      //     let id = $(this).attr("id");
      //     switch (id) {
      //       case "rescuan":
      //         setCurrentMint(NftOwners.rescuan);
      //         break;
      //       case "validator":
      //         setCurrentMint(NftOwners.validator);
      //         break;
      //       case "governor":
      //         setCurrentMint(NftOwners.governor);
      //       default:
      //         break;
      //     }

          // if ($(window).width() < 1024) return;

          // let divs = $("#types-of-nft").find("div");
          // // animate this div to center
          // if ($(this).css("left") == "0px") {
          //   $(this).removeClass("left-unique");
          //   $(this).removeClass("right-unique");
          //   $(this).removeClass("center-unique");
          //   $(this).addClass("center-unique");
          //   $(this).css("transform", "scale(1.4)");
          //   $(this).animate({
          //     left: $("#types-of-nft").width() / 2,
          //     marginLeft: -160,
          //     opacity: 1
          //   });
          //   // get left 50% div
          //   let center = divs
          //     .filter(function () {
          //       return (
          //         $("#types-of-nft").width() / 2 ==
          //         Number(
          //           $(this)
          //             .css("left")
          //             .replace("px", "")
          //         )
          //       );
          //     })
          //     .first();
          //   center.css("transform", "scale(1)");
          //   center.removeClass("left-unique");
          //   center.removeClass("right-unique");
          //   center.removeClass("center-unique");
          //   center.addClass("left-unique");
          //   center.animate({
          //     left: 0,
          //     opacity: 0.7,
          //     marginLeft: "0px"
          //   });
          // } else if ($(this).css("left") == $("#types-of-nft").width() + "px") {
          //   $(this).removeClass("left-unique");
          //   $(this).removeClass("right-unique");
          //   $(this).removeClass("center-unique");
          //   $(this).addClass("center-unique");
          //   $(this).css("transform", "scale(1.4)");
          //   $(this).animate({
          //     left: $("#types-of-nft").width() / 2,
          //     marginLeft: -160,
          //     opacity: 1
          //   });
          //   // get left 50% div
          //   let center = divs
          //     .filter(function () {
          //       return (
          //         $("#types-of-nft").width() / 2 ==
          //         Number(
          //           $(this)
          //             .css("left")
          //             .replace("px", "")
          //         )
          //       );
          //     })
          //     .first();
          //   center.css("transform", "scale(1)");
          //   center.removeClass("left-unique");
          //   center.removeClass("right-unique");
          //   center.removeClass("center-unique");
          //   center.addClass("right-unique");
          //   center.animate({
          //     left: $("#types-of-nft").width(),
          //     opacity: 0.7,
          //     marginLeft: -300
          //   });
          // } else {
          //   $(this).removeClass("left-unique");
          //   $(this).removeClass("right-unique");
          //   $(this).removeClass("center-unique");
          //   $(this).removeAttr("center-unique");
          //   $(this).css("transform", "scale(1.4)");
          //   $(this).css("opacity", "1");
          // }

      //     // change color
      //     if ($(this).attr("id") == "governor") {
      //       // text color change
      //       $("#nft-content-text").css("color", "#DD515A");
      //       $(".nft-content-sm").css("color", "#DD515A");
      //       $("#nft-content-number").html("10");
      //       $("#nft-content-price").html("500,000 USD");
      //       $("#nft-content-netzium").html("25,000,000");
      //       $("#token-amount-sng").html("100,000");
      //       $("#token-amount-eve").html("100,000");
      //       $("#token-amount-mf3").html("100,000");
      //       $("#token-amount-nrz").html("100,000");
      //       $("#token-amount-gam").html("100,000");
      //       $("#token-amount-nzm").html("100,000");
      //       // image color change
      //       $("#sng").attr("src", "assets/images/epc/sng-governor.svg");
      //       $("#eve").attr("src", "assets/images/epc/eve-governor.svg");
      //       $("#mf3").attr("src", "assets/images/epc/mf3-governor.svg");
      //       $("#nrz").attr("src", "assets/images/epc/nrz-governor.svg");
      //       $("#gam").attr("src", "assets/images/epc/gam-governor.svg");
      //       $("#nzm").attr("src", "assets/images/epc/nzm-governor.svg");
      //     } else if ($(this).attr("id") == "rescuan") {
      //       // text color change
      //       $("#nft-content-text").css("color", "#DF7C3C");
      //       $(".nft-content-sm").css("color", "#DF7C3C");
      //       $("#nft-content-number").html("40,000");
      //       $("#nft-content-price").html("500 USD");
      //       $("#nft-content-netzium").html("25,000");
      //       $("#token-amount-sng").html("100");
      //       $("#token-amount-eve").html("100");
      //       $("#token-amount-mf3").html("100");
      //       $("#token-amount-nrz").html("100");
      //       $("#token-amount-gam").html("100");
      //       $("#token-amount-nzm").html("100");
      //       // image color change
      //       $("#sng").attr("src", "assets/images/epc/sng-rescuan.svg");
      //       $("#eve").attr("src", "assets/images/epc/eve-rescuan.svg");
      //       $("#mf3").attr("src", "assets/images/epc/mf3-rescuan.svg");
      //       $("#nrz").attr("src", "assets/images/epc/nrz-rescuan.svg");
      //       $("#gam").attr("src", "assets/images/epc/gam-rescuan.svg");
      //       $("#nzm").attr("src", "assets/images/epc/nzm-rescuan.svg");
      //     } else {
      //       // text color change
      //       $("#nft-content-text").css("color", "#282F66");
      //       $(".nft-content-sm").css("color", "#282F66");
      //       $("#nft-content-number").html("2,000");
      //       $("#nft-content-price").html("5000 USD");
      //       $("#nft-content-netzium").html("250,000");
      //       $("#token-amount-sng").html("1000");
      //       $("#token-amount-eve").html("1000");
      //       $("#token-amount-mf3").html("1000");
      //       $("#token-amount-nrz").html("1000");
      //       $("#token-amount-gam").html("1000");
      //       $("#token-amount-nzm").html("1000");
      //       // image color change
      //       $("#sng").attr("src", "assets/images/epc/sng-validator.svg");
      //       $("#eve").attr("src", "assets/images/epc/eve-validator.svg");
      //       $("#mf3").attr("src", "assets/images/epc/mf3-validator.svg");
      //       $("#nrz").attr("src", "assets/images/epc/nrz-validator.svg");
      //       $("#gam").attr("src", "assets/images/epc/gam-validator.svg");
      //       $("#nzm").attr("src", "assets/images/epc/nzm-validator.svg");
      //     }
      //   });
      // });

    //   // on resize
    //   $(window).resize(function () {
    //     console.log($(window).width());
    //     if ($(window).width() > 1265) {
    //       $(".left-unique").css("left", 0);
    //       $(".right-unique").css("left", $("#types-of-nft").width());
    //       $(".center-unique").css("left", $("#types-of-nft").width() / 2);
    //       $(".center-unique").css("margin-left", -160);
    //       $(".right-unique").css("margin-left", -360);
    //       $(".center-unique").css("transform", "scale(1.4)");
    //     } else {
    //       $(".left-unique").css("left", 0);
    //       $(".right-unique").css("left", 0);
    //       $(".center-unique").css("left", 0);
    //       $(".center-unique").css("margin-left", 0);
    //       $(".right-unique").css("margin-left", 0);
    //       $(".center-unique").css("transform", "scale(1)");
    //     }
    //   });
    // });
          // END JN COMMENTED: TODO GET THIS ANIMATION WORKING AGAIN
  }, [pending]);

  return (
    <>

      {pending ? (
        <div>
          {/* <!-- Default Modal --> */}
          <div
            style={{ background: "#060606a3" }}
            id="success-modal"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center"
            aria-hidden="true"
          >
            <div className="relative p-4 w-full max-w-lg h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center p-5 rounded-t dark:border-gray-600">
                  {/* <button onClick={removeSuccessModal} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="medium-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Close modal</span>
              </button> */}
                </div>

                {/* <!-- Modal body start --> */}
                <div className="text-center">
                  <div className="w-full flex justify-center">
                    <img
                      src={"/setting-animated.gif"}
                      width="150px"
                      height="150px"
                      alt=""
                    />
                  </div>
                  <div className="mt-4 pb-14">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                      Minting
                    </h2>
                    <p className="mt-2 font-bold text-green-500 text-center">
                      We are assigning your EPC to your account, please hold
                    </p>
                    <div className="mt-4" id="wait"></div>
                    {/* <button
                    onClick={mailSend}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-3"
                  >
                    {sendingMail ? "Sending ..." : "Resend"}
                  </button> */}
                  </div>
                </div>
                {/* <!-- Modal body end --> */}
                {/* <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
              <button data-modal-toggle="medium-modal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
              <button data-modal-toggle="medium-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : ""}

      <section id="epcs" className="bg-gradient-to-b from-rescuablue-300 py-12 text-gray-50 to-white" data-pg-collapsed>
        <div className="container mx-auto mb-2 px-4">
          <div className="mb-6 text-center">
            <h1 className="poppins-semibold mb-2 text-5xl text-white">
              JOIN OUR MISSION
            </h1>
            <h3 className="font-bold text-2xl nft-content-sm text-rescuablue-700">
              Choose an EPC below and click the mint button to start your journey
            </h3>

            <div className="mt-4">
              <section id="epc-explainer-text" className="bg-gray-50 py-8 rounded-xl shadow-md text-center text-gray-500">
                <div className="w-full flex justify-center text-center p-4 mt-6">
                  <div className="text-2xl mb-8 poppins-semi-bold">
                    <div className="p-10 text-justify">
                      <div className="md:flex m-6 text-center md:text-left w-full justify-center">
                        <div className="tracking-wider poppins-bold text-rescuablue-500">
                          THE RESCUA EARTH
                        </div>
                        <div className="w-full md:w-60 flex md:block justify-center">
                          <svg id="MINT_NFT" data-name="MINT NFT" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 445 70" className="px-1 w-60" stroke="rgb(119 178 223 / var(--tw-bg-opacity))" strokeWidth={3} strokeLinecap="round" strokeLinejoin="miter" fill="none" fillRule="evenodd">
                            <path d="M91 16v12h23v15H91v12h35v15H74V1h52v15H91zM199 1v69h-18V50l-15-8-8-4v32h-17V1h17v20l16 8 7 4V1h18zm32 15v12h23v15h-23v12h35v15h-52V1h52v15h-35zm67 0v12h41v42h-58V55h40V43h-40V1h58v15h-41zm55-15h17v69h-17zm50 15v12h41v42h-58V55h40V43h-40V1h58v15h-41zM18 16v39h23V43h-8V28h26v42H1V1h58v15H18z" />
                          </svg>
                        </div>
                        <div className="tracking-wider poppins-bold mt-auto text-rescuablue-500">
                          EMPOWERMENT CERTIFICATE LAUNCH
                        </div>
                      </div>
                      <div className="text-sm md:text-base text-rescuablue-700 mb-4">
                        GENESIS EPC stakeholders are helping us launch of our first community-owned renewable energy projects and governance technology. As a reward for becoming an early stakeholder, holders of GENESIS EPCs gain the right to additional digital tokens in each of the launch projects.
                        <span id="dots2" className="text-amber-500">...</span>
                      </div>
                      <div id="more2" className="hidden">
                        <div className="text-sm md:text-base text-rescuablue-700 mb-4">
                          The Netzium Token represents 100 KwH of clean energy supply in our projects. In future phases, Netzium tokens will be tradeable for energy. GENESIS stakeholders will be given additional Netzium tokens as a reward for becoming an early participant. After the GENESIS launch, the Netzium (NZM) reward for each Rescuan, Validator and Governor package will vary depending on the country in which it is purchased. This reflects the differential targets for NetZero and economic growth required in each country and aims to incentivise investment in economies that are currently struggling to have access to sufficient clean energy.
                        </div>
                        <div className="text-sm md:text-base text-rescuablue-700 mb-4">
                          Additionally, holders of GENESIS EPC packages will be rewarded with additional stakeholder tokens in key projects from Sync.
                        </div>
                        <div className="text-sm md:text-base text-rescuablue-700 mb-4">
                          Finally, please note, this will be the only opportunity to gain tokens in Sync Neural Group itself.
                        </div>
                      </div>
                      <div className="py-4 text-center ">
                        <button onClick={toggleText2} id="morebutton2"
                                  className="bg-red-600 text-white hover:bg-red-700 duration-150 ease-in-out inline-block leading-tight px-6 py-1.5 rounded-lg text-sm transition">
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div id="mint-section" className="container mx-auto">
            <div className="-mx-4 flex flex-wrap justify-center">
              <div className="container mx-auto mt-4">
                <div id="types-of-nft" className="mt-0 grid grid-cols-1 md:grid-cols-3 xl:relative">

                  { mintDisplayColls.map(epcCollObj => (
                    <MintableEPC key={epcCollObj.name} epcCollObj={epcCollObj}
                            onClick={() => setSelectedEPCCollObj(epcCollObj)} isSelected={epcCollObj===selectedEPCCollObj}></MintableEPC>
                  ))}

                </div>
                {/* grid end */}

                {/* Type of EPC and Minting section content start */}
                <div className="mt-20">
                  <section className="bg-gray-50 py-8 rounded-xl shadow-md text-center text-gray-500">
                    <div>
                      {/* mint button start */}
                      <button style={{
                        background: selectedEPCCollObj.mainColour,
                        visibility: selectedEPCCollObj.mintButtonVisibility
                      }}
                        onClick={() => handleMint(selectedEPCCollObj.creator)}
                        className="text-white p-10 py-2 rounded-xl"
                                type="submit"
                                name="submit">
                        Mint
                      </button>
                      {/* mint button end */}
                    </div>
                    <div className="w-full flex justify-center text-center mt-5">
                      <div className="text-md mb-8 px-8 poppins-regular"
                                style={{ color: selectedEPCCollObj.mainColour }}>
                        <div className='poppins-semi-bold'>
                          {selectedEPCCollObj.type} GENESIS EARLY STAKEHOLDER EMPOWERMENT CERTIFICATE DETAILS
                        </div>
                        <div className="my-2">
                          {/* TODO: dynamic */}
                          <span className='poppins-semi-bold'>Current available tokens for sale: </span>
                          {currentlyAvailable}<span> of </span>
                          {numFormat.format(selectedEPCCollObj.tokenSupply)}
                        </div>
                        <div className="my-2">
                          <span className='poppins-semi-bold'>
                            MultiversX eGld Price: </span>
                          {numFormat.format(selectedEPCCollObj.priceEGold)}
                          <p className="text-sm my-2">Note, EPCs are purchased with MultiversX eGold.</p>
                          <p className="text-sm">To cover the small transaction fee, <br />
                            please ensure you have at least {numFormat.format(selectedEPCCollObj.priceEGold + 1)} eGold in your wallet.</p>
                        </div>
                        <div className="my-2 poppins-semi-bold">
                          <span poppins-semi-bold>Approximate Equivalent USD Price: </span>
                          { numFormat.format(selectedEPCCollObj.priceUSD) }
                        </div>
                        <div className="my-2">
                          <span poppins-semi-bold>Genesis Exchangable Netzium Tokens: </span>
                          {numFormat.format(selectedEPCCollObj.tokens['gnz'])}
                        </div>
                      </div>
                    </div>
                    <div className="container mx-auto relative">
                      <div className="flex flex-wrap -mx-4 justify-center"
                                style={{ color: selectedEPCCollObj.mainColour }} >
                          { Object.keys(tokenDetails).map(token => (
                            <div key={token} className="p-4 sm:w-6/12 md:w-2/12">
                              <Token displayColourHex={ selectedEPCCollObj.mainColour }
                                          tokenDetails={ { ...tokenDetails[token], tokenAmount: selectedEPCCollObj.tokens[token] }}></Token>
                            </div>
                          ))}
                      </div>
                    </div>
                  </section>
                </div>
                {/* Type of EPC and Minting section content end */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />

    </>
  );
}
